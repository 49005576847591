// index.html
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from 'twin.macro';
import { ContractStartDateTimeLabel } from 'components/datetime/ContractStartDateTimeLabel';
import { DateTimeLabel } from 'components/datetime/DateTimeLabel';
import { Layout } from 'components/layouts';
import { WalletConnectButton } from 'components/wallet/ConnectButton';
import { HeaderConnectButton } from 'components/wallet/HeaderConnectButton';
import { useStaking } from 'hooks/staking/useStaking';

export const Index: React.FC = React.memo(() => {
  const history = useHistory();
  const staking = useStaking();
  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      setDatetime(result.headers.date);
    };
    fetchDateTime();
  }, []);

  useEffect(() => {
    // const scriptUrljQuery = document.createElement('script');
    // scriptUrljQuery.type = 'text/javascript';
    // scriptUrljQuery.src = '/lending/jquery-3.1.1.min.js';
    // const scriptUrljQuery = document.createElement('script');
    // scriptUrljQuery.src = 'https://code.jquery.com/jquery-3.1.1.min.js';
    // scriptUrljQuery.integrity = 'sha256-hVVnYaiADRTO2PzUGmuLJr8BLUSjGIZsDYGmIJLv2b8=';
    // scriptUrljQuery.crossOrigin = 'anonymous';
    // document.body.appendChild(scriptUrljQuery);

    const scriptUrlIscroll = document.createElement('script');
    scriptUrlIscroll.type = 'text/javascript';
    scriptUrlIscroll.src = '/lending/iscroll.min.js';
    scriptUrlIscroll.async = true;

    const scriptUrlDrawer = document.createElement('script');
    scriptUrlDrawer.type = 'text/javascript';
    scriptUrlDrawer.src = '/lending/drawer.min.js';
    scriptUrlDrawer.async = true;

    const scriptUrlCustom = document.createElement('script');
    scriptUrlCustom.type = 'text/javascript';
    scriptUrlCustom.src = '/lending/script.js';
    scriptUrlCustom.async = true;

    document.body.appendChild(scriptUrlIscroll);
    document.body.appendChild(scriptUrlDrawer);
    document.body.appendChild(scriptUrlCustom);

    return () => {
      // document.body.removeChild(scriptUrljQuery);
      document.body.removeChild(scriptUrlIscroll);
      document.body.removeChild(scriptUrlDrawer);
      document.body.removeChild(scriptUrlCustom);
    };
  }, []);

  return (
    <Layout id="index">
      <StyledDivKv>
        <StyledHeader>
          <StyledNav>
            <StyledUlLeft>
              <li>
                <a href="#">TOP</a>
              </li>
              <li>
                <a href="#section_006">NEWS</a>
              </li>
              <li>
                <a href="#section_001">FSCCとは</a>
              </li>
              <li>
                <a href="#section_002">特徴</a>
              </li>
              <li>
                <a href="#section_003">ご利用の流れ</a>
              </li>
              <li>
                <a href="#section_004">仕組み</a>
              </li>
              <li>
                <a href="#section_005">運営会社</a>
              </li>
            </StyledUlLeft>
            <div>
              <HeaderConnectButton
                onClick={() => {
                  history.push('/lending/mypage');
                }}
              ></HeaderConnectButton>
            </div>
          </StyledNav>
          <StyledSpButton className="drawer-toggle drawer-hamburger">
            <span className="sr-only">toggle navigation</span>
            <span className="drawer-hamburger-icon"></span>
          </StyledSpButton>
          <StyledNavDrawer className="drawer-nav" role="navigation">
            <StyledUlDrawer className="drawer-menu scroll-container">
              <li>
                <a href="#">TOP</a>
              </li>
              <li>
                <a href="#section_006">NEWS</a>
              </li>
              <li>
                <a href="#section_001">FSCCとは</a>
              </li>
              <li>
                <a href="#section_002">特徴</a>
              </li>
              <li>
                <a href="#section_003">ご利用の流れ</a>
              </li>
              <li>
                <a href="#section_004">仕組み</a>
              </li>
              <li>
                <a href="#section_005">運営会社</a>
              </li>
              <li>
                <div>
                  <HeaderConnectButton
                    onClick={() => {
                      history.push('/lending/mypage');
                    }}
                  ></HeaderConnectButton>
                </div>
              </li>
            </StyledUlDrawer>
          </StyledNavDrawer>
        </StyledHeader>
        <StyledDivInnerKv>
          <StyledPCatchWord>FSCCを貸して増やす</StyledPCatchWord>
          <StyledPKvText>
            FISCOコイン
            <br />
            <span>
              レンディング
              <br />
              アプリケーション
            </span>
          </StyledPKvText>
          <section>
            <div>
              <StyledTitlePredictSection>年換算貸借料率（予定）</StyledTitlePredictSection>
              <StyledPPredict>
                （
                <DateTimeLabel
                  date={datetime ? new Date(datetime) : new Date()}
                  monthOnly={false}
                />{' '}
                現在）
              </StyledPPredict>
              <StyledPPredictApy>
                {Number(staking.apy).toFixed(2).toString() == '0.00'
                  ? '-'
                  : Number(staking.apy).toFixed(2).toString() + '%'}
                <br />
                <span>※現在の全てのお客様からの総貸借数量から算出</span>
              </StyledPPredictApy>
            </div>
            <div>
              <StyledTitlePredictSection> 次期ご利用開始時期 </StyledTitlePredictSection>
              <StyledPNextTerm>
                <ContractStartDateTimeLabel isTop={true} />
                <br />
                <span>※お申込みは随時受付け中</span>
              </StyledPNextTerm>
            </div>
          </section>
        </StyledDivInnerKv>
      </StyledDivKv>

      <StyledDivWrap>
        <TopStyledButton>
          <WalletConnectButton
            onClick={() => {
              history.push('/lending/mypage');
            }}
          />
        </TopStyledButton>
        <StyledSectionEnvironment id="metamask">
          <StyledHeaderReadMe>
            ※必ずお読みください。
            <br />
            ご利用には下記の環境が必須となります。
          </StyledHeaderReadMe>
          <StyledDivReadMe>
            ▼ご利用環境
            <ul>
              <li>・Chromeブラウザ</li>
              <li>・MetaMaskアプリ</li>
            </ul>
            <a
              target="_blank"
              href="https://fiscocoin.com/lending/introduction.html"
              rel="noreferrer"
            >
              設定方法
            </a>
          </StyledDivReadMe>
        </StyledSectionEnvironment>
      </StyledDivWrap>
      <StyledSection id="section_006">
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>NEWS</StyledHeaderTitle>
            <StyledUlNews>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.10.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20241028-2.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20241028-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.10.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20241028-1.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20241028-1_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.10.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20241015.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第46回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20241015_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.09.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240925.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240925_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.09.17</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240917-2.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第45回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240917-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.09.17</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240917-1.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240917-1_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.08.26</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240826.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240826_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.08.20</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240820.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第44回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240820_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.08.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240815.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240815_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.07.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240725.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240725_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.07.23</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240723.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第43回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240723_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.07.17</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240717.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240717_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.06.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240625-2.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第42回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240625-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.06.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240625-1.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240625-1_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.06.17</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240617.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240617_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.05.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240528.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第41回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240528_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.05.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240524.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240524_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.05.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240515.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240515_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.04.30</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240430.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第40回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240430_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.04.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240425.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240425_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.04.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240415.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240415_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.04.02</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240402.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第39回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240402_en_.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.03.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240325.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240325_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.03.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240315.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240315_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.03.05</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240305.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第38回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240305_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.02.27</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240227.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240227-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.02.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240215.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240215_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.02.06</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240206.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第37回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240206_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.01.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240125-2.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240125-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.01.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240115.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240115_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2024.01.09</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20240109.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第36回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20240109-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.12.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231225.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231225_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.12.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231215.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231215_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.12.12</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231212.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第35回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231212_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.11.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231124.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231124_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.11.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231115.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231115_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.11.14</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231114.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第34回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231114_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.10.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231025.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231025_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.10.17</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231017.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第33回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231017_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.10.13</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20231013.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20231013_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.09.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230925.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230925_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.09.19</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230919.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第32回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230919_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.09.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230915.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230915_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.08.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230825.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230825_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.08.22</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230822.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第31回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230822_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.08.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230815.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr-20230815_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.07.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230725-2.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第30回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230725-2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.07.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230725.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230725_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.07.14</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230714.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230714_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.06.27</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230627.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第29回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230627_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.06.23</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230623.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230623_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.06.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230615.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230615_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.05.30</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230530.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第28回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230530_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.05.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230525.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230525_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.05.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230515.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230515_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.05.02</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230502.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第27回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230502_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.04.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230425.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230425_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.04.14</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230414.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230414_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.04.04</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230404.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第26回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230404_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.03.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230324.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230324_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.03.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230315.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230315_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.03.07</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230307.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第25回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230307_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.02.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230224.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230224_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.02.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230215.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230215_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.02.07</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230207.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第24回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230207_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.01.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230125.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230125_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.01.16</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230116.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230116_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2023.01.10</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20230110.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第23回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20230110_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.12.26</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221226.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221226_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.12.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221215.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221215_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.12.13</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221213.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第22回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221213_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.11.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221125_1.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221125_1_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.11.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221115_2.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221115_2_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.11.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221115_1.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第21回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221115_1_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.10.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221025.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221025_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.10.18</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221018.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第20回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221018_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.10.14</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20221014.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20221014_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.09.26</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220926.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220926_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.09.20</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220920.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第19回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220920_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.09.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220915.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220915_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.08.31</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220831.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>
                    FSCCレンディングの年換算貸借料率のお知らせ
                  </StyledPNewsMainText>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.08.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220825.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220825_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.08.23</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220823.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第18回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220823_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.08.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220815.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220815_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.07.26</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220726.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第17回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220726_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.07.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220725.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220725_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.07.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220715.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディング の 経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220715_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.07.14</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr20220714.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>
                    NFTマーケットプレイスに追加機能ガチャを実装
                  </StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr20220714_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.07.08</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220708.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>
                    FSCCレンディングの年換算貸借料率のお知らせ
                  </StyledPNewsMainText>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.06.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220628.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第16回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220628_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.06.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220624.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220624_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.06.15</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220615.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220615_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.05.31</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220531.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの第15回募集をスタート</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220531_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.05.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220525.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCC購入枚数の経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220525_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.05.16</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/fdap_pr_20220516.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>FSCCレンディングの経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220516_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.05.03</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第14回募集をスタート_20220503.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第14回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/FSCC_Lending_14th_20220503_en.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.04.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【FDAP_PR】FSCCレンディングの経過開示_20220428.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの経過開示</StyledPNewsMainText>
                </a>
                <a href="/lending/fdap_pr_20220428_en.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.04.05</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第13回募集をスタート_20220405.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第13回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/FSCC_Lending_13th_20220405_en.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.03.08</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第12回募集をスタート_20220308.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第12回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/FSCC_Lending_12th_20220308_en.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.02.08</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第11回募集をスタート_20220208.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第11回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第11回募集をスタート_20220208_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2022.01.11</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第10回募集をスタート_20220111.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第10回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第10回募集をスタート_20220111_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.12.14</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第9回募集をスタート_20211214.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第9回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第9回募集をスタート_20211214_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.11.16</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第8回募集をスタート_20211116.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第8回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第8回募集をスタート_20211116_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.10.19</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第7回募集をスタート_20211019.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第7回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第7回募集をスタート_20211019_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.10.12</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第7回募集をスタート_20211012.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第7回募集告知</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第7回募集をスタート_20211012_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.09.21</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第6回募集をスタート20210921.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第6回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第6回募集をスタート20210921_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.08.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第5回募集をスタート20210824.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第5回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第5回募集をスタート20210824_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.07.27</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第4回募集をスタート20210727.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第4回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第4回募集をスタート20210727_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.06.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第3回募集をスタート20210628.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第3回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第3回募集をスタート20210628_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.06.25</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第3回募集をスタート20210625_2.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第3回募集告知</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第3回募集をスタート20210625_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.06.01</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第2回募集をスタート_20210601.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第2回募集をスタート</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第2回募集をスタート_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.05.24</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングの第2回募集告知_210524.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>FSCCレンディングの第2回募集告知</StyledPNewsMainText>
                </a>
                <a
                  href="/lending/【PR】FSCCレンディングの第2回募集告知_210524_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>

              <li>
                <div>
                  <time dateTime="2021-1-1">2021.05.10</time>
                  <p className="cat01">PR</p>
                </div>
                <a
                  href="/lending/【PR】FSCCレンディングアプリケーションの募集をスタート20210510.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StyledPNewsMainText>
                    FSCCレンディングアプリケーションの募集をスタート
                  </StyledPNewsMainText>
                </a>

                <a
                  href="/lending/【PR】FSCCレンディングアプリケーションの募集をスタート20210510_English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="cat01">English</p>
                </a>
              </li>
              <li>
                <div>
                  <time dateTime="2021-1-1">2021.04.28</time>
                  <p className="cat01">PR</p>
                </div>
                <a href="/lending/202104280001.pdf" target="_blank" rel="noreferrer">
                  <StyledPNewsMainText>
                    FSCCレンディングアプリケーションをリリース
                  </StyledPNewsMainText>
                </a>
                <a href="/lending/202104280001_e.pdf" target="_blank" rel="noreferrer">
                  <p className="cat01">English</p>
                </a>
              </li>
            </StyledUlNews>
          </div>
        </StyledDivContainerWide>
      </StyledSection>
      <StyledSection id="section_001">
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>
              FSCCとは
              <StyledDivFlowListContainer>
                <a href="https://fiscocoin.jp/" target="_blank" rel="noreferrer">
                  <figure>
                    <StyledImg src="/lending/logo_fscc02.png"></StyledImg>
                  </figure>
                </a>
              </StyledDivFlowListContainer>
            </StyledHeaderTitle>
            <StyledPText>
              FSCC(FISCOコイン)は、株式会社フィスコが発行する企業トークンです。決済等を通じて実経済で使用する機会（FSCC経済圏）の拡大を目標としています。
              <br />
            </StyledPText>
          </div>
        </StyledDivContainerWide>
      </StyledSection>
      <StyledSectionGrey id="section_002">
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>FISCOコインレンディングアプリケーションの特徴</StyledHeaderTitle>
          </div>
          <StyledSectionContent>
            <StyledDivFeatureListContainer>
              <StyledDivFeatureList>
                <StyledDivFeatureListIcon>
                  <StyledImg src="/lending/icon02.png"></StyledImg>
                </StyledDivFeatureListIcon>
                <StyledHeader3FeatureListTitle>
                  168日ごとで貸借料がもらえる。
                </StyledHeader3FeatureListTitle>
                <p>
                  168日間を1つの期間として貸出し可能。168日ごとで貸出を継続するか否かを判断することができます。
                </p>
              </StyledDivFeatureList>
              <StyledDivFeatureList>
                <StyledDivFeatureListIcon>
                  <StyledImg src="/lending/icon03.png"></StyledImg>
                </StyledDivFeatureListIcon>
                <StyledHeader3FeatureListTitle>
                  貸出の継続は自動更新でラク。
                </StyledHeader3FeatureListTitle>
                <p>
                  複雑な更新手続きはなく自動更新です。お客様は資産を貸出しする以外にすることはありません。
                </p>
              </StyledDivFeatureList>
              <StyledDivFeatureList>
                <StyledDivFeatureListIcon>
                  <StyledImg src="/lending/icon04.png"></StyledImg>
                </StyledDivFeatureListIcon>
                <StyledHeader3FeatureListTitle>
                  セキュリティ重視のサービス設計。
                </StyledHeader3FeatureListTitle>
                <p>万全のセキュリティ体制で管理しています。</p>
              </StyledDivFeatureList>
            </StyledDivFeatureListContainer>
          </StyledSectionContent>
        </StyledDivContainerWide>
      </StyledSectionGrey>
      <StyledSectionFlow id="section_003">
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>
              <StyledImg src="/lending/logo_001.png" alt=""></StyledImg>ご利用の流れ
              <StyledBrSp></StyledBrSp>
            </StyledHeaderTitle>
          </div>
          <StyledSectionContent>
            <StyledFlowListContainer>
              <figure>
                {/* <StyledImg src="/lending/flow.png"></StyledImg> */}
                <StyledImg src="/lending/flow2.png"></StyledImg>
              </figure>
            </StyledFlowListContainer>
          </StyledSectionContent>

          <TopStyledButton>
            <WalletConnectButton
              onClick={() => {
                history.push('/lending/mypage');
              }}
            />
          </TopStyledButton>
        </StyledDivContainerWide>
      </StyledSectionFlow>

      <StyledSectionHow id="section_004">
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>
              FISCOコインレンディングアプリケーション<StyledBrPc></StyledBrPc>の仕組み
            </StyledHeaderTitle>
          </div>

          <StyledSectionContent>
            <p>
              FISCOコインレンディングアプリケーションはお客様が保有する暗号資産FISCOコインを、当該アプリケーションにおけるスマートコントラクトに貸出し、貸借数に応じた貸借料としてFISCOコインを受け取れるサービスです。
              <br />
              このサービスの特徴として、弊社が今まで培ってきたリサーチや開発支援などのブロックチェーンに関連する業務から培った知見や技術を活かし、お客様にとっての資産運用のパフォーマンス向上を考え、お客様が貸出した総貸借数量によって貸借料が変動するシステムを採用しています。
              <br />
              お客様に安心してご利用いただけるよう、リスクコントロールに万全を期し、サービスの向上に努め、卓越したパフォーマンスの実現を目指します。
            </p>
            <StyledFlowListContainer>
              <figure>
                <StyledImg src="/lending/setsumei01_03.png"></StyledImg>
              </figure>
            </StyledFlowListContainer>
          </StyledSectionContent>
        </StyledDivContainerWide>
      </StyledSectionHow>

      <StyledSectionCompany id="section_005">
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>運営会社</StyledHeaderTitle>
          </div>
          <StyledSectionContent>
            <StyledCompanyListContainer>
              <StyledPCompany>
                株式会社FISCO Decentralized Application Platform
                <br />
                大阪府岸和田市荒木町2丁目18番15号
              </StyledPCompany>
            </StyledCompanyListContainer>
          </StyledSectionContent>
        </StyledDivContainerWide>
      </StyledSectionCompany>

      <StyledCtaSection>
        <StyledDivContainerWide>
          <div>
            <StyledHeaderTitle>
              さっそく効率的な
              <br />
              資産活用をしましょう
            </StyledHeaderTitle>
          </div>
          <TopStyledButton>
            <WalletConnectButton
              onClick={() => {
                history.push('/lending/mypage');
              }}
            />
          </TopStyledButton>
        </StyledDivContainerWide>
      </StyledCtaSection>
      <br />
      <br />

      <StyledFooter>
        <StyledDivToTop>
          {' '}
          <a href="#">
            ▲<br />
            <span>TOP</span>
          </a>
        </StyledDivToTop>
        <StyledFooterInner>
          <StyledSpanFooterCopy>
            © 2021 株式会社FISCO Decentralized Application Platform
          </StyledSpanFooterCopy>
          <StyledSpanFooterCopy>
            <a
              href="/lending/FDAPプライバシーポリシー20210501.pdf"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>
          </StyledSpanFooterCopy>
        </StyledFooterInner>
      </StyledFooter>
    </Layout>
  );
});

const StyledDivKv = styled.div`
  position: relative;
  height: 640px;
  padding: 0;
  background-color: #0069c0;
  background-image: url('/lending/kv.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;

  @media (max-width: 959px) {
    height: 480px;
  }
`;

const StyledHeader = styled.header`
  padding: 16px;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: #0058a1b5;
  z-index: 300;
  box-sizing: border-box;
`;

const StyledNav = styled.nav`
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  li {
    margin-right: 8px;
  }

  li:last-child {
    margin-right: 0;
  }

  li a {
    color: #fff;
    font-weight: 400;
  }

  @media (max-width: 959px) {
    display: none;
  }
`;

const StyledUlLeft = styled.ul`
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  scroll-behavior: smooth;

  li {
    list-style: none;
    margin-right: 8px;
  }

  li:last-child {
    margin-right: 0;
  }

  li a {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
  }
`;

const StyledSpButton = styled.button`
  display: none;

  @media (max-width: 959px) {
    display: block;
  }
`;

const StyledNavDrawer = styled.nav`
  background-color: #0069c0;
  display: none;

  @media (max-width: 959px) {
    display: block;
  }
`;

const StyledUlDrawer = styled.ul`
  padding: 8px;
  scroll-behavior: smooth;
  li {
    margin-top: 8px;
  }
  li:first-child {
    margin-top: 0;
  }

  .li:last-child {
    margin-top: 32px;
  }

  li a {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
  }
`;

const StyledDivInnerKv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  z-index: 3;

  @media (max-width: 959px) {
    width: 90%;
    width: calc(100% - 32px);
    padding: 16px;
  }
`;

const StyledPKvText = styled.p`
  font-size: 96px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-weight: 900;

  @media (max-width: 959px) {
    font-size: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-weight: 900;
  }

  span {
    font-size: 50px;
    line-height: 0.5em;
  }

  @media (max-width: 959px) {
    span {
      font-size: 30px;
    }
  }
`;

const StyledTitlePredictSection = styled.h2`
  font-size: 18px;
  color: white;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: 24px;

  @media (max-width: 959px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const StyledPPredict = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  text-align: center;
  color: white;
`;

const StyledPPredictApy = styled.p`
  color: #eee800;
  font-size: 50px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  font-weight: 12px;
  margin-top: 20px;

  @media (max-width: 959px) {
    font-size: 40px;
    line-height: 20px;
  }

  span {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    font-weight: 100;
    color: white;
  }
`;

const StyledPNextTerm = styled.p`
  margin-top: 8px;
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  font-weight: 12px;

  span {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    font-weight: 100;
    color: white;
  }
`;

const StyledDivWrap = styled.div`
  width: 496px;
  margin: 30px auto;
  padding-bottom: 48px;

  @media (max-width: 959px) {
    margin: 20px auto;
    width: 100%;
  }
`;

const StyledSection = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledHeaderTitle = styled.h2`
  color: #0069c0;
  font-size: 300%;
  line-height: 1.23456789em;
  padding: 2.5em 0 1em;
  font-weight: 900 !important;
  text-align: center;
  @media only screen and (max-width: 520px) {
    font-size: 220%;
  }
`;

const StyledPText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  font-size: 140%;
  line-height: normal;
`;

const TopStyledButton = styled.div`
  display: block;
  padding: 0;
  text-decoration: none;
`;

const StyledSectionEnvironment = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #e0e0e0;
  padding-top: 16px;
  border: none;
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.25);
  border-radius: 16px;
  padding: 2em;
  box-sizing: border-box;

  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledHeaderReadMe = styled.h2`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  color: #f44336;
  margin-top: 0px;
  text-align: center;
  font-size: 140%;
  line-height: normal;
  margin-top: 0px;
  text-align: center;
  font-size: 20px;
`;

const StyledDivReadMe = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  margin-top: 16px;
  text-align: center;
  font-size: 140%;
  line-height: normal;

  li {
    font-weight: 600;
    margin-top: 8px;
    list-style: none;
  }

  a {
    line-height: calc(48px - 2px);
    height: 48px;
    border-radius: 0;
    display: block;
    margin-top: 16px;
    border: 1px solid #0069c0;
    width: 300px;
    margin: 16px auto;
    text-decoration: none;
    opacity: 1;
  }

  a:hover,
  a:active {
    color: #424242;
    color: #9e9e9e;
    font-weight: 600;
    text-decoration: underline;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.75;
  }

  a:link,
  a:visited {
    color: #424242;
    font-weight: 600;
    text-decoration: none;
  }

  a::after {
    border-top: 2px solid #424242;
    border-right: 2px solid #424242;
  }
`;

const StyledDivContainerWide = styled.div`
  width: 1024px;
  margin: 0 auto;
  padding-bottom: 48px;
  @media only screen and (max-width: 960px) {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
  }
`;

const StyledDivFlowListContainer = styled.div`
  padding-top: 2.5em;
`;

const StyledSectionGrey = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  background: #f5f7fa;

  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledSectionContent = styled.div`
  p {
    font-size: 130%;
    line-height: 1.89em;
  }
`;

const StyledDivFeatureListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
  margin-top: 2.5em;
  padding-bottom: 1.5em;
  @media only screen and (max-width: 520px) {
    margin-top: 0;
  }
`;

const StyledDivFeatureList = styled.div`
  flex-basis: 21%;
  margin: 1.5% 2%;
  p {
    font-size: 106% !important;
    line-height: 1.5678em;
  }

  @media only screen and (max-width: 520px) {
    flex-basis: 100%;
    margin: 2.5em auto auto;
    text-align: center;
  }

  @media only screen and (min-width: 521px) and (max-width: 960px) {
    flex-basis: 46%;
    margin: 1.5% 2%;
  }
`;

const StyledDivFeatureListIcon = styled.div`
  margin: auto;
  text-align: center;
  margin-bottom: 1.5em;

  span {
    text-align: center;
    margin: auto;
    display: block;
    font-size: 480%;
    line-height: 1;
    width: 200px;
    height: 200px;
    padding-top: 1em;
    color: rgba(71, 133, 215, 1);
    box-sizing: border-box;
    border-radius: 200%;
    border: 1px solid #ccc;
  }
`;

const StyledHeader3FeatureListTitle = styled.h3`
  margin-bottom: 1em;
  font-weight: 900;
  font-size: 150% !important;
  line-height: 1.3456789em;
  text-align: center;
  @media only screen and (max-width: 520px) {
    font-size: 140% !important;
  }
`;

const StyledBrPc = styled.br`
  display: block;

  @media (max-width: 959px) {
    display: none;
  }
`;

const StyledBrSp = styled.br`
  display: none;

  @media (max-width: 959px) {
    display: block;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: auto;
`;

const StyledSectionFlow = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  background: #fff;
  margin-top: -2.35em;
  position: relative;
  figure {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }

  figure img {
    margin: auto;
    text-align: center;
  }

  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledFlowListContainer = styled.div`
  padding-top: 2.5em;
`;

const StyledSectionHow = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  background: #f5f7fa;
  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledSectionCompany = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  font-family: sans-serif;
  background: #fff;
  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledCompanyListContainer = styled.div`
  padding: 2.5em 0;
`;

const StyledPCompany = styled.p`
  font-size: 130%;
  text-align: center;
`;

const StyledCtaSection = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  font-family: sans-serif;
  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

// Footer
const StyledFooter = styled.footer`
  display: block;
  width: 100%;
  background-color: #424242;
  background: #434a54;
  padding: 5em 0 2.5em;
  margin-top: -2.35em;
  position: relative;
  z-index: 111;
  p a {
    text-align: center;
    font-size: 140%;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    padding: 1em 0 0.75em;
    display: block;
  }
  address {
    font-size: 90%;
    font-style: normal;
    color: rgba(255, 255, 255, 0.75);
  }
  @media only screen and (max-width: 960px) {
    p a {
      font-size: 110%;
    }
    address {
      font-size: 80%;
    }
  }
`;

const StyledDivToTop = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  width: 50px;
  height: 50px;
  background: #ffffffb5;
  border-radius: 30px;
  font-size: 10px;
  text-align: center;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%);
  a {
    text-decoration: none;
    font-weight: 700;
  }
`;

const StyledFooterInner = styled.div`
  padding: 32px 0;
  display: block;

  @media (max-width: 959px) {
    display: flex;
    display: -ms-flex;
    display: -webkit-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const StyledSpanFooterCopy = styled.span`
  display: block;
  text-align: center;
  margin-top: 16px;
  color: #fff;

  a {
    color: #fff;
  }
`;

const StyledPNewsMainText = styled.ul`
  width: 746px;

  @media (max-width: 959px) {
    padding-left: 0px;
    width: 100%;
  }
`;

const StyledUlNews = styled.ul`
  border-top: 1px solid #aaaaaa;
  width: 100%;

  li {
    border-bottom: 1px solid #aaaaaa;
    font-size: 18px;
    list-style: none;
  }
  li a {
    box-sizing: border-box;
    color: #000000;
    /* display: table; */
    padding: 15px;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;

    @media (max-width: 959px) {
      display: block;
    }
  }

  li a:hover {
    background: #eeeeee;
    text-decoration: none;
  }
  li div {
    display: table-cell;
    width: 180px;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
    vertical-align: middle;

    @media (max-width: 959px) {
      display: block;
      padding-bottom: 15px;
      padding-top: 15px;
      margin: auto;
    }
  }
  li a {
    display: table-cell;
    padding-left: 10px;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;

    @media (max-width: 959px) {
      padding-left: 0px;
    }
  }
  li p {
    display: table-cell;
    padding-left: 10px;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;

    @media (max-width: 959px) {
      padding-left: 0px;
    }
  }

  li time {
    display: table-cell;
    vertical-align: top;
    width: 90px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  li .cat01 {
    background-color: #1b73ba;
    border-radius: 1px;
    color: #ffffff;
    font-size: 9px;
    padding: 0 2px;
    text-align: center;
    width: 50px !important;
  }
  li .cat02 {
    background-color: #1b73ba;
    border-radius: 1px;
    color: #ffffff;
    font-size: 9px;
    padding: 0 2px;
    text-align: center;
    width: 50px;
  }
  li .cat02 {
    background-color: #0e3a5f;
  }
`;

const StyledPCatchWord = styled.p`
  font-size: 24px;
  line-height: 5px;
  font-weight: 100;
  text-align: center;
  color: white;
  margin-bottom: 30px;
  margin-top: 20px;
  z-index: 5000;

  @media (max-width: 959px) {
    font-size: 18px;
    line-height: 5px;
    font-weight: 100;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    margin-top: 30px;
    z-index: 5000;
  }
`;
